import React from 'react';
import {Platform} from 'react-native';
import * as RNIap from 'react-native-iap';
import { AppContext } from '../context';
import { Functions } from '../constants/Firebase';

const {useEffect,useState}=React;
const InitialState = { initialzed:false,loading:false };
const itemSubs = Platform.select({
    ios:['optimizer_1','optimizer_2'],
    //ios: ['optimizer_sub','optimizer_yearly_sub'],
    //android: ['optimizer_sub','optimizer_yearly_sub'],
    android: ['optimizer_sub','optimizer_sub'],
});

const androidOfferId = {monthly:'optimizer-sub',yearly:'optimizer-year-sub-renew'};

export const usePayments = (start=true)=>{
    const {state:AppState} = React.useContext(AppContext);
    const [state,setState] = React.useState(InitialState);
    const [subs,setSubs] = useState<any>(null);
    const [product,setProduct] = useState<any>();
    const setStoredData = Functions.httpsCallable(Functions.getFunctions(),'startSubscriptionv2');

    const requestSubscription = async (plan:'monthly'|'yearly'='monthly')=>{
        //console.log('product: ', product);
        try {
            setState({...state,loading:true});
            //@ts-ignore
            let sku = itemSubs[0];
            let offerToken = '';
            if(!itemSubs) throw new Error('Cannot subscribe from this device.');
            const item = plan=='monthly'?itemSubs[0]:itemSubs[1];

            if(subs){
                const product = subs.find((i:any) => i.productId === item);
                sku = product.productId;
                if(product.subscriptionOfferDetails){
                    if(Platform.OS == 'android'){
                        const subscriptionOfferDetails = product.subscriptionOfferDetails;
                        offerToken = subscriptionOfferDetails.find((i:any) => i.basePlanId === androidOfferId[plan]).offerToken;
                    }
                    else{
                        offerToken = product?.subscriptionOfferDetails[0].offerToken??'';
                    }
                }
            }

            const res = await RNIap.requestSubscription({
                sku:sku,
                subscriptionOffers:[{
                    sku:sku,
                    offerToken: offerToken
                }]
            });
            //console.log('res: ', res);
        } catch (err:any) {
            console.log('err: ', err);
            //alert(err.message);
            alert("Can not start your subscription right now.\nPlease try again later.");
        }
        finally{
            setState({...state,loading:false});
        }
    }

    const getAvailablePurchases = async ()=>{
        switch(Platform.OS){
            case 'android':{
                const purchases = await RNIap.getAvailablePurchases();
                //console.log('purchases: ', purchases.length);
                if (purchases.length > 0) {
                    switch(AppState.subscription.type){
                        case 'ended':{
                            const purchase = purchases[0];
                            //await setStoredData({os:Platform.OS,'subscription':JSON.stringify(purchase)});
                            break;
                        }
                    }
                }
                else{
                    switch(AppState.subscription.type){
                        case 'paid':{
                            //await setStoredData({os:Platform.OS,'subscription':null});
                            break;
                        }
                    }
                }
                break;
            }
            case 'ios':{
                
                break;
            }
            default:{
                const purchases = await RNIap.getAvailablePurchases();
                //console.log('purchases: ', purchases.length);
                if (purchases.length > 0) {
                    
                }
                else{
                    
                }
                break;
            }
        }
    }

    const initialize = async(subsItems = itemSubs)=>{
        try{
            const result = await RNIap.initConnection();
            console.log('result: ', result);
            if (result) {
                if(Platform.OS == 'android') await RNIap.flushFailedPurchasesCachedAsPendingAndroid();
                //@ts-ignore
                const sub = await RNIap.getSubscriptions({skus:itemSubs});
                //console.log('sub: ', sub);
                //@ts-ignore
                const product = sub.find((i) => i.productId === itemSubs[0]);
                setProduct(product);
                setSubs(sub);
                
                if( Platform.OS == 'ios' ){
                    for(let i=0; i<sub.length; i++){
                        //@ts-ignore
                        if( sub[i].productId === itemSubs[0] ) sub[i].type = 'monthly';
                        //@ts-ignore
                        if( sub[i].productId === itemSubs[1] ) sub[i].type = 'yearly';
                    }
                }
                
                getAvailablePurchases();
            }
        }
        catch(error:any){
            console.log('RNIapErr: ',error);
            //alert("Can not start your subscription right now.\nPlease try again later.");
        }
    }
 
    useEffect(()=>{
        if(state.initialzed) return;
        let listener = null;
        let errListener = null;
        initialize()
        .then(()=>{
            setState({...state,initialzed:true});
            listener = RNIap.purchaseUpdatedListener(async (purchase) => {
                setState({...state,loading:true});
                await setStoredData({os:Platform.OS,'subscription':JSON.stringify(purchase)});
                await RNIap.finishTransaction({purchase,isConsumable:false});
                setState({...state,loading:false});
            }); 
            errListener = RNIap.purchaseErrorListener(async(purchase)=>{
                console.log('errPurchase: ', purchase);
            });
        })
        .catch((error)=>{
            //alert(error.message);
        });
    },
    [state.initialzed]);

    return {...state,subs,getAvailablePurchases,requestSubscription};
}