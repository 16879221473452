import React from 'react';
import { Dimensions, ImageBackground, StyleSheet, Text, View, } from 'react-native';
import { Appbar, Badge, useTheme } from 'react-native-paper';
import dayjs from 'dayjs';
import { currencyFormatter } from '../constants/utils';
import { useDailyTotals } from '../hooks/useDailyTotals';
import { VictoryArea, VictoryAxis, VictoryChart, VictoryLabel, VictoryLegend, VictoryLine, VictoryScatter, VictoryTheme } from "victory-native";
import Strings from '../constants/Strings';

const HEIGHT = Dimensions.get('window').height;
const WIDTH = Dimensions.get('window').width;

export default function HomeGraph({setSpendFree=(s:number)=>{},uid=null}) {
    const { colors } = useTheme();
    const styles = Styles(colors);
    const {labels,spendFreeDays,totals} = useDailyTotals(uid);
    const month = dayjs().format('MMMM');

    React.useEffect(()=>{setSpendFree(spendFreeDays)},[spendFreeDays])

    if(labels.length == 0){
        return (
            <View style={styles.contentContainer}>
                {/* <ListEmpty invert={true} type={'spendingGraph'} /> */}
            </View>
        );
    }

    const getLabelOffset = (line1End:{x:number,y:number}, line2End:{x:number,y:number}) => {
        // Adjust offsets based on proximity of end points
        if (Math.abs(line1End.y - line2End.y) < 1) {
          return { line1: { dx: -10, dy: -15 }, line2: { dx: 10, dy: 15 } };
        }
        return { line1: { dx: 0, dy: -5 }, line2: { dx: 0, dy: -5 } };
      };

    const Graph2 = ()=>{
        //console.log('totals: ', totals.expenseXY[1]);
        //@ts-ignore
        totals.expenseXY[0] = totals?.expenseXY[0]? [{x:0,y:0},...totals.expenseXY[0]] : [{x:0,y:0}];
        //@ts-ignore
        totals.expenseXY[1] = totals?.expenseXY[1]? [{x:0,y:0},...totals.expenseXY[1]] : [{x:0,y:0}];

        let arrLength = totals?.expenseXY[1]?.length??0;
        let arrLength2 = totals?.expenseXY[0]?.length??0;
        
        
        let lastPost = arrLength>0?totals.expenseXY[1][arrLength-1]:{x:0,y:0};
        let lastPost2 = arrLength2>0?totals.expenseXY[0][arrLength2-1]:{x:0,y:0};

        const offsets = getLabelOffset(lastPost, lastPost2);


        const yTick = lastPost2.y<=0? 1 : Math.round(lastPost2.y/500000)+1;

        const date = new Date();
        const lastDay = Number( new Date(date.getFullYear(), date.getMonth(), 0).getDate() );
        
        const right = (WIDTH*0.12);
        
        const height = HEIGHT>1000?
                            HEIGHT*0.44
                        :
                            HEIGHT>800?
                                HEIGHT*0.41
                            :
                                HEIGHT*0.39;
        let bottom = 50;//WIDTH>1200?30:WIDTH>500?50:25;
        //if(height > 400) bottom = 40;
        if(WIDTH>500) bottom = 100;
        let left = 12;
        

        return (
            <>
            <VictoryChart
                domain={{ x: [0, lastDay],  }}
                domainPadding={{ x: 25 }}
                padding={{ top: 45, bottom: bottom, right: right, left: left }}
                theme={VictoryTheme.material}
                polar={false}
                style={{parent: {},background: {fill:colors.primary,opacity: 0}}}
                width={WIDTH}
                height={height}
                animate={{duration: 2000,onLoad: { duration: 1000 }}}
            >
                <VictoryLegend
                    x={0} y={2}
                    orientation='vertical'
                    gutter={0}
                    rowGutter={{ top: -10, bottom: 0 }}
                    style={{ 
                        labels: {fontSize:WIDTH>500?20:12},
                        title: {color:colors.accent, fontSize: 20 } 
                    }}
                    data={[
                        { name: "Last Month", symbol: { fill: colors.primary } },
                        { name: "This Month", symbol: { fill: colors.accent }, labels: { fill: colors.accent } },
                    ]}
                />
                
                <VictoryLine
                    style={{ 
                        data: { stroke: colors.primary} 
                    }}
                    data={totals.expenseXY[0]??[]}
                    //scale={{x: "time", y: "linear"}}
                />
                <VictoryScatter 
                    data={[lastPost2]}
                    size={WIDTH>500?8:5}
                    style={{ 
                        data: { fill: colors.primary },
                        labels: {fontSize: WIDTH>500?22:15,fill: colors.primary}
                    }}
                    labels={({ datum }:any) => currencyFormatter(datum.y)}
                    labelComponent={
                        <VictoryLabel dx={offsets.line2.dx} dy={offsets.line2.dy} />
                      }
                />


                <VictoryLine
                    style={{ 
                        data: { stroke: colors.accent,strokeWidth:2} 
                    }}
                    data={totals.expenseXY[1]??[]}
                    //scale={{x: "time", y: "linear"}}
                    //interpolation={'linear'}
                />
                <VictoryScatter 
                    data={[lastPost]}
                    size={WIDTH>500?8:5}
                    style={{ 
                        data: { fill: colors.accent },
                        labels: {fontSize: WIDTH>500?22:15,fill: colors.accent,zIndex:200}
                    }}
                    labels={({ datum }:any) => currencyFormatter(datum.y)}
                    labelComponent={
                        <VictoryLabel dx={offsets.line1.dx} dy={offsets.line1.dy} />
                      }
                />

                <VictoryAxis
                    tickCount={ yTick }
                    dependentAxis={ true } /* To target the y-axis */
                    style={{ 
                        axis: {stroke: "transparent"}, 
                        grid: {stroke: "transparent"}, 
                        ticks: {stroke: "transparent"},
                        tickLabels: {fill:"transparent"}
                    }} 
                    tickFormat={() => ''}
                />

                <VictoryAxis 
                    style={{ 
                        axis: {stroke: colors.primary}, 
                        axisLabel:{fontSize:WIDTH>500?25:18,fill:colors.accent,padding:25,textAlign:'center'},
                        grid: {stroke: "transparent"}, 
                        ticks: {stroke: "transparent"},
                        tickLabels: {fontSize:WIDTH>500?20:15, fill:colors.accent}
                    }} 
                    tickCount={5}
                    tickFormat={(x) => Number.isInteger(x)? x : ' '}
                    //label={month}
                />
            </VictoryChart>
            </>
        );
    }

    return(
        <View>
            <Graph2 />
        </View>
    )
}

const Styles = (colors: any) => {
    const Height = Dimensions.get('window').height*0.3;
    return (
        StyleSheet.create({
            cardTitle:{
                fontSize: 18,
                textAlign:'center'
            },
            contentContainer:{
                minHeight: Height,
                marginBottom: 10,
                padding:10
            },
            labelText: {
                color: colors.accent,
                fontSize: 15,
                marginBottom: 10,
                marginRight: 20
            },
            totalBalance: {
                color: colors.accent,
                fontSize: 14,
                fontWeight: 'bold',
                marginBottom: 10,
                zIndex:200
            },
        })
    );
}