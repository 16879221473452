import React from 'react';
import { Dimensions, Text, StyleSheet, View, ScrollView, RefreshControl, ImageBackground, } from 'react-native';
import { Button, List, useTheme } from 'react-native-paper';
import { calculatePaymentPeriod, currencyFormatter, } from '../constants/utils';
import { useSpendingCalculator } from '../hooks/useSpendingCalculator';
import { AppContext } from '../context';
import { Loading, LoadingModal } from '../components/LoadingModal';
import { useFocusEffect } from '@react-navigation/native';
import { usePayments } from '../hooks/usePayments';
import { Optimizer } from './Optimizer';
import Strings from '../constants/Strings';
import RBSheet from 'react-native-raw-bottom-sheet';
import SelectSubscriptionSheet from '../components/SelectSubscriptionSheet';

const HEIGHT = Dimensions.get('window').height;
const WIDTH = Dimensions.get('window').width;

export default function PaymentOptimizer(props: any) {
    const { state } = React.useContext(AppContext);
    const { subscription } = state;
    const { initialzed, loading, subs, getAvailablePurchases, requestSubscription } = usePayments();

    useFocusEffect(
        React.useCallback(() => {
            if (!initialzed) return;
            getAvailablePurchases();
        }, [initialzed])
    );

    // return <Optimizer {...props} />

    switch (subscription?.type) {
        case 'beta':
        case 'trial':
        case 'free':
        case 'paid': {
            return <Optimizer {...props} />
        }
        default: {
            if(props?.route?.params?.uid) return <Optimizer {...props} />
            
            return <Subscription {...props} initialzed={initialzed} loading={loading} subscriptions={subs} requestSubscription={requestSubscription} type={subscription.type} />
        }
    }
}

function Subscription(props: any) {
    const { initialzed, loading, requestSubscription, subscriptions, type } = props;
    const { colors } = useTheme();
    const styles = Styles(colors);
    const { state } = React.useContext(AppContext);
    const RBRef = React.useRef<RBSheet | null>();

    const {loadingPlan,payPlan,percentReductionTime,savingAmount,totalWithoutPlan, planWithoutOptimization } = useSpendingCalculator();

    React.useEffect(()=>{
        props.navigation.setOptions({
            headerRight: () => (<View/>) //reset header
        });
    },[])

    async function handleSubmit(type:'monthly'|'yearly') {
        await requestSubscription(type);
    }

    return (
        <View style={{ flex: 1 }}>
            <LoadingModal visible={loadingPlan} />
            <ImageBackground source={require('../../assets/onboarding/paymentscreen.png')} resizeMode={'cover'} style={{ ...styles.image, height: HEIGHT * 0.30 }}>
            </ImageBackground>
            <ScrollView
                contentContainerStyle={styles.container2}
                refreshControl={<RefreshControl refreshing={false} onRefresh={() => { planWithoutOptimization(0) }} />}
                showsVerticalScrollIndicator={false}
            >
                <Text style={{ ...styles.text, color: colors.primary }}>
                    {'You\'ll be debt free in '}
                    {
                        loadingPlan?
                        <Loading visible={true} size='small' />
                        :
                        <Text style={{ color: colors.primary, fontWeight: 'bold' }}>{calculatePaymentPeriod(totalWithoutPlan)}</Text>
                    }
                    {' without Payment Optimization.'}
                </Text>

                <Text style={{ ...styles.text, color: colors.primary }}>
                    Let the Legacy App streamline your life and reduce the time you spend worrying about your current financial situation
                    {
                        percentReductionTime>0?
                            <Text style={styles.text}> by<Text style={{...styles.text,fontWeight: 'bold'}}>{' '+percentReductionTime+'% '}</Text></Text>
                        :
                            <Text/>
                    }
                    {
                        savingAmount>0?
                            <Text>
                                getting you out of debt in 
                                <Text style={{ ...styles.text,fontWeight: 'bold' }}> {calculatePaymentPeriod(payPlan.length)} </Text> 
                                while saving you 
                                <Text style={{ ...styles.text,fontWeight: 'bold' }}> {currencyFormatter(savingAmount,true,false)} </Text> 
                                in interest costs.
                            </Text>
                        :
                            <Text/>
                    }
                </Text>

                <Text style={styles.text}>
                    For only <Text style={{ color: colors.primary, fontWeight:'bold' }}>{Strings.OPTIMIZER_PRICE} a month/{Strings.OPTIMIZER_PRICE_YEAR} a year for 24/7 access</Text>, {'the Legacy App can provide financial advice, a variety of tools and resources to help you track your spending, help you decide on a plan for paying off debt, and plan for a future as you work toward your financial goals.'}
                </Text>
                <Text style={styles.text}>
                    Subscriptions come with no commitment; you can cancel at any time.
                </Text>

                {initialzed ?
                    <Button
                        style={styles.button}
                        labelStyle={{ fontSize: WIDTH > 1000 ? 25 : 15 }}
                        onPress={async () => { RBRef.current?.open(); }}
                        color={colors.primary}
                        mode={'contained'}
                        uppercase={true}
                        disabled={loading}
                        loading={loading}
                    >
                        Subscribe Now
                    </Button>
                    :
                    <Loading style={styles.button} size={'small'} visible={true} />
                }

            </ScrollView>
            <SelectSubscriptionSheet loading={loading} RBRef={RBRef} subscriptions={subscriptions} requestSubscription={requestSubscription}/>
            
        </View>
    );
}

const Styles = (colors: any) => {
    return (
        StyleSheet.create({
            button: {
                alignSelf: 'center',
                borderRadius: 10,
                height: WIDTH > 1000 ? 85 : 55,
                justifyContent: 'space-around',
                marginTop: 25,
                //width: '50%'
            },
            container: {
                backgroundColor: colors.background,
                flex: 1,
                padding: 10,
            },
            container2: {
                alignContent: 'center',
                alignSelf: 'center',
                backgroundColor: colors.background,
                flexGrow: 1,
                //padding: WIDTH > 1000 ? 50 : 20,
                paddingLeft: WIDTH > 1000 ? 150 : 50,
                paddingRight: WIDTH > 1000 ? 150 : 50,
            },
            card: {
                marginBottom: 10,
                maxHeight: '50%',
            },
            col: {
                alignContent: 'center',
                alignItems: 'center',
                borderColor: colors.placeholder,
                borderWidth: 0.5,
                height: 60,
                justifyContent: 'center',
                width: 110,
            },
            colHeader: {
                borderColor: colors.placeholder,
                borderWidth: 0.5,
                height: 60,
                width: 110,
            },
            colHeaderText: {
                color: colors.primary,
                fontWeight: 'bold',
                paddingLeft: 5,
                paddingRight: 5,
                textAlign: 'center'
            },
            divider: {
                backgroundColor: colors.backdrop,
                marginLeft: -20,
                marginTop: 10,
                width: '110%'
            },
            image: {
                alignSelf: 'center',
                width: '100%',
                height: HEIGHT * 0.45,
            },
            row: {

            },
            text: {
                color: colors.primary,
                fontSize: WIDTH > 1000 ? 30 : 15,
                paddingTop: WIDTH > 1000 ? 30 : 10,
                textAlign: 'left'
            },
            toggleBtn: { display: 'flex', justifyContent: 'center' },
            toggleBtnBtn: { width: WIDTH / 3, height: 75 },
            toggleBtnText: {
                color: colors.primary,
                fontSize: 20,
                fontWeight: 'bold'
            }
        })
    );
}