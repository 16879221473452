// @ts-nocheck 
import React from 'react';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

export function About({about1Des,about2Des,about3Des,about1Title,about2Title,about3Title}:any) {
  return (
    <div className="features-section" id="about-the-app">
    <div className="container ">
      <div className="features-image-container">
        <Zoom><img className="section-img2" src="img/screen 2.png" /></Zoom>
      </div>
      <div className="feature-textbox2">
        <p className="text">This figure compares approximately 20 financial apps across 34 key categories, highlighting the most sought-after features. The Legacy Financial App strategically excludes four outdated options—Simplified Budgeting Snapshot, Monthly Budgeting, Personalized Savings Goals, and Envelope Budgeting—to focus on exponentially faster debt repayment than any other platform.</p>
        <p className='text'>Despite omitting these features, the Legacy Financial App leads the industry, covering 27 of 30 key categories (90%), with three more features in development to achieve 100% coverage. The app is available for $9.99/month or $99.99/year.</p>
        <p className='text'>Competitive Comparison:</p>
        <p className='text'>Legacy Financial App: 27/30 categories (90%) → Soon: 30/30 (100%)<br/>
          PocketGuard: 9/30 categories (30%) | $74.99/year | Covers 70% fewer categories<br/>
          Monarch Money: 8/30 categories (27%) | $99.99/year | Covers 73% fewer categories<br/>
        </p>
        <p className='text'>With patent-pending Debt Snowball and Debt Blizzard Calculators, real-time banking integration, and Zero-Based Budgeting, Legacy Financial App is the #1 choice for achieving financial freedom faster! 🚀</p>
      </div>

      <div className="">
        <div className="feature-textbox">
          <div className="icon"><ion-icon className="icon" name="color-palette-outline" /></div>
          <div className="feature-text">
            <h3>{about1Title}</h3>
            <p className="text">{about1Des}</p>
          </div>
        </div>
        <div className="feature-textbox">
          <div className="icon"><ion-icon className="icon" name="albums-outline" /></div>
          <div className="feature-text">
            <h3>{about2Title}</h3>
            <p className="text">{about2Des}</p>
          </div>
        </div>
        <div className="feature-textbox">
          <div className="icon"><ion-icon className="icon" name="cellular-outline" /></div>
          <div className="feature-text">
            <h3>{about3Title}</h3>
            <p className="text">{about3Des}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
